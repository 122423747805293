import React from "react";
import { useGLTF } from "@react-three/drei";

const pos_and_rot = [
  { position: [13.98, -0.49, 12.79], rotation: [0.1, 0, 0.03] },
  { position: [13.6, -1.31, 14.08], rotation: [0.05, 0.01, 0.18] },
  { position: [12.37, -0.55, 13.6], rotation: [-0.01, 0, 0.09] },
  { position: [35.37, -0.49, 7.24], rotation: [0.18, -0.58, 0.05] },
  { position: [12.74, -0.67, 12.31], rotation: [0, 0, 0] },
  { position: [34.74, -0.49, 6.07], rotation: [0.01, -0.14, 0.09] },
  { position: [13.15, -0.59, 13.49], rotation: [-0.01, 0.12, 0.07] },
  { position: [36.16, -0.45, 6.11], rotation: [3.01, -1.19, 3.05] },
  { position: [37.04, -1.4, 7.36], rotation: [0.13, -0.59, -0.02] },
  { position: [0.67, -0.53, 18.81], rotation: [0.05, -0.07, 0.18] },
  { position: [1.12, -0.47, 17.64], rotation: [0.05, -0.07, 0.18] },
  { position: [34.51, -0.49, 7.06], rotation: [3.04, -0.11, -3.12] },
  { position: [2.38, -0.39, 18.59], rotation: [0.06, 0.59, 0.15] },
  { position: [2.09, -0.51, 17.62], rotation: [0.05, -0.44, 0.2] },
  { position: [1.63, -0.42, 18.52], rotation: [0.05, -0.18, 0.19] },
  { position: [2.04, -1.29, 19.62], rotation: [0.13, -0.22, 0.17] },
];

export default function Reed(props) {
  const { nodes, materials } = useGLTF("/reed.glb");

  return (
    <group {...props} dispose={null}>
      {pos_and_rot.map((data, index) => {
        return (
          <group
            key={data.position[0]}
            position={data.position}
            rotation={data.rotation}
          >
            <mesh
              castShadow
              geometry={nodes.Cube017.geometry}
              material={materials["schilf_grass.001"]}
            />
            <mesh
              castShadow
              geometry={nodes.Cube017_1.geometry}
              material={materials["schilf_wood.001"]}
            />
            <mesh
              castShadow
              geometry={nodes.Cube017_2.geometry}
              material={materials["schilf_stengel.001"]}
            />
          </group>
        );
      })}
    </group>
  );
}
