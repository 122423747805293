import { useAnimations, useGLTF } from "@react-three/drei";
import React, { useEffect, useRef } from "react";

import { useAnimationsStore } from "../store/animation_store";

export default function AxeAndWood(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/axe_and_wood.glb");
  const { actions } = useAnimations(animations, group);

  const { myWorkAnimation } =
    useAnimationsStore();

  useEffect(() => {
    if (myWorkAnimation === true) {
      actions["axeAction"].play();
    }

    if (myWorkAnimation === false) {
      actions["axeAction"]?.stop();
    }
  }, [myWorkAnimation, actions]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="axe_and_wood001"
          position={[-13.38, 2.25, 5.09]}
          rotation={[-0.26, -0.51, -0.01]}
          scale={1.41}
        >
          <mesh
            name="wood"
            castShadow
            geometry={nodes.wood.geometry}
            material={materials["Material.004"]}
          >
            <group name="axe" position={[0.92, 1, -0.23]}>
              <mesh
                name="Cube136"
                castShadow
                geometry={nodes.Cube136.geometry}
                material={materials["Material.003"]}
              />
              <mesh
                name="Cube136_1"
                castShadow
                geometry={nodes.Cube136_1.geometry}
                material={materials["blade.001"]}
              />
            </group>
          </mesh>
        </group>
      </group>
    </group>
  );
}
