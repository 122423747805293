import create from "zustand";

export const useAnimationsStore = create((set) => ({
  contactFormAnimation: false,
  startContactFormAnimation: () => set({ contactFormAnimation: true }),

  aboutMeAnimation: false,
  startAboutMeAnimation: () => set({ aboutMeAnimation: true }),

  myWorkAnimation: false,
  startMyWorkAnimation: () => set({ myWorkAnimation: true }),

  viewBackToWorldOverlay: null,
  setViewBackToWorldOverlay: () => set({ viewBackToWorldOverlay: true }),
  resetViewBackToWorldOverlay: () => set({ viewBackToWorldOverlay: false }),

  resetAnimations: () =>
    set({
      myWorkAnimation: false,
      aboutMeAnimation: false,
      contactFormAnimation: false,
    }),
}));
