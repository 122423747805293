import React from "react";
import { useGLTF } from "@react-three/drei";

export default function HouseSD(props) {
  const { nodes, materials } = useGLTF("/house_sd.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[-17.93, 2.24, -5.12]} rotation={[0, 0.11, 0]}>
        <mesh
          castShadow
          geometry={nodes.Cube015.geometry}
          material={materials["bench.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_1.geometry}
          material={materials["house_surface.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_2.geometry}
          material={materials["wood.002"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_3.geometry}
          material={materials["window_light.002"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_4.geometry}
          material={materials["roof.002"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_5.geometry}
          material={materials["door_handle.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_6.geometry}
          material={materials["house_outside.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_7.geometry}
          material={materials["ps_house_outside.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube015_8.geometry}
          material={materials["floor.001"]}
        />
      </group>
    </group>
  );
}
