import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";

export default function SkyMain(props) {
  const { nodes } = useGLTF("/sky_main.glb");

  const bakedTexture = useTexture("./sky.jpg");

  return (
    <group {...props} dispose={null}>
      <mesh
        receiveShadow
        geometry={nodes.Sky_main.geometry}
        material={nodes.Sky_main.material}
        position={[0.11, 8.2, -33]}
        rotation={[0, Math.PI / 2, 0]}
      >
        <meshStandardMaterial map={bakedTexture} map-flipY={false} />
      </mesh>
    </group>
  );
}

