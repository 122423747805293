import React, { useContext } from "react";
import { DeviceDetectContext } from "../Scene";

// Static
import Bench from "./Details/Bench";
import Bushes from "./Details/Bushes";
import Campfire from "./Details/Campfire";
import Deck from "./Details/Deck";
import Grass from "./Details/Grass";
import Logs from "./Details/Logs";
import Reed from "./Details/Reed";
import Stones from "./Details/Stones";
import Fisherman from "./Fisherman";
import HouseHD from "./House/HouseHD";
import HouseSD from "./House/HouseSD";
import HunterStandHD from "./HunterStand/HunterStandHD";
import HunterStandSD from "./HunterStand/HunterstandSD";
import Laternen from "./Lanterns";
import NavigationPanel from "./NavigationPanel";
import Tent from "./Tent";
import Terrain from "./Terrain";
import TerrainSky from "./TerrainSky";
import Trees from "./Trees";
import Water from "./Water";

// Interactive
import AxeAndWood from "./AxeAndWood";
import Bear from "./Bear";

export default function SceneAssets() {
  const isMobile = useContext(DeviceDetectContext);

  return (
    <>
      <StaticAssets isMobile={isMobile} />
      <InteractiveComponents />
    </>
  );
}


const StaticAssets = ({ isMobile }) => {
  return (
    <>
      <Laternen />
      <Terrain />
      <Trees />
      <Stones />
      <Reed />
      <Grass />
      <Bushes />
      <Deck />
      <Bench />
      <Campfire />
      <Logs />
      <Water />

      {isMobile ? <HouseSD /> : <HouseHD />}
      {isMobile ? <HunterStandSD /> : <HunterStandHD />}
      <Tent />
      <TerrainSky />
      <NavigationPanel />
    </>
  );
};

const InteractiveComponents = () => {
  return (
    <>
      <Fisherman />
      <AxeAndWood />
      <Bear />
    </>
  );
};
