import { useGLTF, useTexture } from "@react-three/drei";

const models = (SD) => {
  return [
    "/campfire.glb",
    "/logs.glb",
    "/bench.glb",
    "/bushes.glb",
    "/deck.glb",
    "/reed.glb",
    "/stones.glb",
    "/tent.glb",
    "/grundflaeche.glb",
    "/tree.glb",
    "/laternen.glb",
    "/fisherman.glb",
    "/bear.glb",
    "/axe_and_wood.glb",
    "/water_viewblocker.glb",
    "/sky_main.glb",
    "/sky_left.glb",
    "/navigation_panel.glb",
    "/navigation_panel_html.glb",
    "/water.glb",
    SD ? "/hunterstand_sd.glb" : "/hunterstand.glb",
    SD ? "/house_sd.glb" : "/house.glb",
  ];
};

export default class PreloadService {
  constructor(SD) {
    this.SD = SD;
    this.models = [...models(this.SD)];
  }

  preLoadModels() {
    useGLTF.preload(this.models);
  }

  preLoadTextures() {
    if (this.SD) return;

    useTexture.preload([
      "./fire.png",
      "./house.png",
      "./hunterstand.png",
      "./sky.jpg",
      "./tent.png",
    ]);
  }
}
