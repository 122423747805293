import React from "react";
import { useGLTF } from "@react-three/drei";

export default function WaterViewBlocker(props) {
  const { nodes } = useGLTF("/water_viewblocker.glb");
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.waterViewBlocker.geometry}>
        <meshStandardMaterial transparent opacity={0.95} color={0x6ba09e}/>
      </mesh>
    </group>
  );
}
