import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";


export default function HunterStandHD(props) {
  const { nodes } = useGLTF("/hunterstand.glb");

  const bakedTexture = useTexture("./hunterstand.png");


  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        geometry={nodes.jägerstand.geometry}
        material={nodes.jägerstand.material}
        position={[29.02, -0.37, -10.81]}
        rotation={[-3.13, 0.45, 3.12]}
      >
        <meshStandardMaterial map={bakedTexture} map-flipY={false} />
      </mesh>
    </group>
  );
}


