import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Bushes(props) {
  const { nodes, materials } = useGLTF("/bushes.glb");
  
  return (
    <group {...props} dispose={null}>
      <group position={[-0.67, 1.68, -6.23]} rotation={[-0.17, 0.05, 0.01]}>
        <mesh
          castShadow
          geometry={nodes.bush001.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush001_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[-2.31, 1.68, -5.81]} rotation={[-2.95, -0.43, -3.06]}>
        <mesh
          castShadow
          geometry={nodes.bush002.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush002_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[-37.88, 2.38, -3.65]} rotation={[-0.17, 0.05, 0.01]}>
        <mesh
          castShadow
          geometry={nodes.bush004.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush004_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[-33.09, 2.38, -6.55]} rotation={[-2.7, -1.15, -2.74]}>
        <mesh
          castShadow
          geometry={nodes.bush005.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush005_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[6.76, 7.51, -21.84]} rotation={[-0.37, -1.07, -0.33]}>
        <mesh
          castShadow
          geometry={nodes.bush007.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush007_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[20.37, -0.57, -22.24]} rotation={[-2.35, -1.33, -2.36]}>
        <mesh
          castShadow
          geometry={nodes.bush008.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush008_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[7.7, 7.27, -23.96]} rotation={[-0.17, 0.05, 0.01]}>
        <mesh
          castShadow
          geometry={nodes.bush006.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush006_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[18.05, -0.42, -20.8]} rotation={[-2.35, -1.33, -2.36]}>
        <mesh
          castShadow
          geometry={nodes.bush009.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush009_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[-34.51, -1.23, 10.98]} rotation={[-0.17, 0.05, 0.01]}>
        <mesh
          castShadow
          geometry={nodes.bush012.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush012_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[17.81, -0.42, -23.92]} rotation={[-2.35, -1.33, -2.36]}>
        <mesh
          castShadow
          geometry={nodes.bush010.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush010_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[-36.68, -0.76, 12.17]} rotation={[-0.26, -0.85, -0.2]}>
        <mesh
          castShadow
          geometry={nodes.bush013.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush013_1.geometry}
          material={materials.Blatt}
        />
      </group>
      <group position={[-35.68, -1.05, 12.98]} rotation={[-0.18, -0.29, -0.05]}>
        <mesh
          castShadow
          geometry={nodes.bush014.geometry}
          material={materials.Stamm}
        />
        <mesh
          castShadow
          geometry={nodes.bush014_1.geometry}
          material={materials.Blatt}
        />
      </group>
    </group>
  );
}
