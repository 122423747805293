import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";

const pos_and_rot = [
  { position: [29.25, -0.38, 8.29], rotation: [0.46, -1.19, 0.46] },
  { position: [29.61, -0.29, 7.4], rotation: [-0.12, -0.49, 0.1] },
  { position: [28.97, -0.39, 7.59], rotation: [-0.19, 0.95, 0.31] },
  { position: [28.91, -0.47, 6.77], rotation: [-0.04, 0.63, 0.13] },
  { position: [29.84, -0.34, 8.05], rotation: [0.18, 0.41, -0.13] },
  { position: [28.64, -0.41, 8.33], rotation: [-0.04, 0.25, 0.17] },
  { position: [28.41, -0.52, 6.95], rotation: [-2.18, 1.53, 2.29] },
  { position: [28.07, -0.52, 7.9], rotation: [-0.04, -0.03, 0.16] },
  { position: [28.19, -0.52, 5.69], rotation: [3.02, -1.15, 3.07] },
  { position: [27.46, -0.62, 6.93], rotation: [-3.08, 0.95, -3.08] },
  { position: [29.26, -0.47, 9.09], rotation: [3.07, 1.15, -3.05] },
  { position: [28.23, -0.48, 8.73], rotation: [2.04, 1.39, -2.02] },
  { position: [28.9, -0.5, 5.86], rotation: [0.05, 0.4, 0.02] },
  { position: [33.88, -0.45, 6.3], rotation: [3.04, 0.83, -3] },
  { position: [33.56, -0.49, 7], rotation: [-0.01, -0.67, 0.06] },
  { position: [3.94, 1.12, 6.62], rotation: [0.02, -0.5, 0.04] },
  { position: [3.23, 1.12, 5.58], rotation: [0.06, 0.62, -0.02] },
  { position: [5.31, 1.07, 5.8], rotation: [0.02, 0.25, -0.07] },
  { position: [4.05, 1.13, 5.58], rotation: [0.08, 0.96, -0.06] },
  { position: [4.21, 1.23, 3.89], rotation: [0, -0.03, 0.04] },
  { position: [6.46, 1, 6.4], rotation: [3.03, 0.96, -3.1] },
  { position: [3.45, 1.19, 4.54], rotation: [1.98, 1.52, -1.94] },
  { position: [5.53, 1.1, 5.09], rotation: [2.16, 1.46, -2.22] },
  { position: [6.51, 1.02, 5.47], rotation: [0.02, 0.38, -0.02] },
  { position: [4.76, 1.2, 4.49], rotation: [0.29, -1.24, 0.21] },
  { position: [5.3, 1.2, 3.66], rotation: [-3.14, 0.85, 3.0] },
  { position: [4.87, 1.09, 6.29], rotation: [3.1, 1.17, 3.12] },
  { position: [-12.16, 2.44, 2.14], rotation: [-3.01, -1.41, -2.96] },
  { position: [5.91, 1.14, 4.32], rotation: [0.04, -0.68, -0.04] },
  { position: [5.09, 0.89, 7.17], rotation: [0.33, 0.36, -0.19] },
  { position: [30.07, -0.56, -8.35], rotation: [0.36, 0.07, -0.25] },
  { position: [-24.19, 2.37, 4.17], rotation: [-0.08, -0.43, -0.21] },
  { position: [29.78, -0.54, -7.71], rotation: [-0.21, 0.74, 0.15] },
  { position: [5.75, 0.96, 6.9], rotation: [-3.14, -1.13, -3] },
  { position: [-24.84, 2.51, 4.56], rotation: [-0.27, -1.27, -0.43] },
  { position: [30.59, -0.5, -8.79], rotation: [0.37, -0.32, -0.11] },
  { position: [-12.19, -0.62, 14.29], rotation: [0.29, 0.29, -0.15] },
  { position: [-24.63, 2.32, 3.67], rotation: [-0.08, 0.81, 0.01] },
  { position: [29.56, -0.44, -8.4], rotation: [0.53, 0.79, -0.53] },
  { position: [-11.38, -0.55, 17.45], rotation: [0.45, 0.91, -0.44] },
  { position: [-11.99, -0.53, 16.93], rotation: [0.34, 0.06, -0.09] },
  { position: [-10.65, -0.49, 14.69], rotation: [0.35, 0.48, -0.24] },
  { position: [-11.93, 2.37, 2.75], rotation: [0.03, -0.3, -0.04] },
  { position: [-24.56, 2.45, 4.36], rotation: [-0.11, -0.75, -0.24] },
  { position: [-13.13, -0.45, 16.76], rotation: [0.32, -0.14, -0.02] },
  { position: [-22.31, -0.58, 17.99], rotation: [0.45, 0.91, -0.44] },
  { position: [-12.31, -0.53, 16.36], rotation: [0.28, -0.07, -0.04] },
  { position: [30.25, -0.33, -9.04], rotation: [2.28, 1.09, -2.56] },
  { position: [-22.01, -0.56, 17.69], rotation: [0.45, 0.91, -0.44] },
  { position: [-12.56, -0.56, 15.06], rotation: [0.33, 0.39, -0.2] },
  { position: [-22.65, -0.54, 17.59], rotation: [0.45, 0.91, -0.44] },
  { position: [-11.62, 2.37, 2.57], rotation: [0.11, -0.3, -0.04] },
  { position: [-13.37, -0.53, 15.79], rotation: [0.31, 0.18, -0.13] },
  { position: [-11.34, -0.5, 16.32], rotation: [0.28, 0.23, -0.14] },
  { position: [-22.4, -0.43, 17.16], rotation: [0.45, 0.91, -0.44] },
];

export default function Grass(props) {
  const { scene } = useGLTF("/grass.glb");
  const copiedScene = useMemo(() => scene.clone(), [scene]);
  const { nodes, materials } = useGraph(copiedScene);

  return (
    <group {...props} dispose={null}>
      {pos_and_rot.map((data, index) => {
        return (
          <mesh
            geometry={nodes.prop_grass001.geometry}
            key={data.position[0]}
            castShadow
            material={materials.grass}
            position={data.position}
            rotation={data.rotation}
          />
        );
      })}
    </group>
  );
}
