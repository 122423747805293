import React, { useRef, useEffect, useState } from "react";
import { CameraControls } from "./cameraControls";
import { useAnimationsStore } from "../store/animation_store";

const PlaceholderBox = React.forwardRef((props, ref) => {
  return (
    <mesh
      {...props}
      ref={ref}
      rotation={props.rotation ? [...props.rotation] : [0, 0, 0]}
      position={props.position ? [...props.position] : [0, 20, 0]}
      scale={props.scale ? props.scale : 1}
    >
      <boxGeometry args={[5, 3, 3]} />
      <meshStandardMaterial visible={false} />
    </mesh>
  );
});

function zoomToElement(cameraRef, el) {
  // disable CameraControls for User
  cameraRef.enabled = false;

  cameraRef.rotateTo(0, Math.PI / 2, true);
  cameraRef.fitToBox(el.current, true, {
    paddingLeft: 1.5,
    paddingRight: 1.5,
    paddingBottom: 1.5,
    paddingTop: 1.5,
  });
}

export default function CameraControlsWithAnimations() {
  const boatBox = useRef(null);
  const bearBox = useRef(null);
  const houseBox = useRef(null);
  const cameraControls = useRef(null);

  const [cameraSettings, setCameraSettings] = useState({});

  const {
    contactFormAnimation,
    aboutMeAnimation,
    myWorkAnimation,
    viewBackToWorldOverlay,
    setViewBackToWorldOverlay,
  } = useAnimationsStore();

  // Zoom-In Handler
  useEffect(() => {
    if (!Boolean(cameraControls.current)) return;

    var target = cameraControls.current.getTarget();
    var offset = cameraControls.current.getFocalOffset();

    setCameraSettings({ target, offset });

    if (contactFormAnimation) {
      zoomToElement(cameraControls.current, boatBox);
      setViewBackToWorldOverlay();
    } else if (aboutMeAnimation) {
      zoomToElement(cameraControls.current, bearBox);
      setViewBackToWorldOverlay();
    } else if (myWorkAnimation) {
      zoomToElement(cameraControls.current, houseBox);
      setViewBackToWorldOverlay();
    }
  }, [
    contactFormAnimation,
    aboutMeAnimation,
    myWorkAnimation,
    setViewBackToWorldOverlay,
  ]);

  // Zoom-Out Handler
  useEffect(() => {
    if (!Boolean(cameraControls.current)) return;

    // viewBackToWorldOverlay is indictator if animations had been reset,
    // and camera view should be reset
    if (viewBackToWorldOverlay === false) {
      cameraControls.current.setOrbitPoint(0, 0, 0);
      cameraControls.current.setPosition(0, 55, 150);
      cameraControls.current.setTarget(
        cameraSettings.target.x,
        cameraSettings.target.y,
        cameraSettings.target.z
      );
      cameraControls.current.setFocalOffset(
        cameraSettings.offset.x,
        cameraSettings.offset.y,
        cameraSettings.offset.z
      );
      cameraControls.current.update();
      cameraControls.current.enabled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBackToWorldOverlay]);

  return (
    <>
      <CameraControls ref={cameraControls} />
      {/* Fisherman */}
      <PlaceholderBox
        position={[33, 2, 16.5]}
        rotation={[0, -Math.PI / 10, 0]}
        ref={boatBox}
      />
      {/* Bear  */}
      <PlaceholderBox
        position={[-26.07, 1.9, 18.65]}
        rotation={[0, Math.PI / 10, 0]}
        ref={bearBox}
      />
      {/* House  */}
      <PlaceholderBox
        position={[-17.93, 8.24, 18.12]}
        rotation={[0, Math.PI / 10, 0]}
        ref={houseBox}
      />
    </>
  );
}
