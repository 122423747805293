import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";


export default function House(props) {
  const { nodes } = useGLTF("/house.glb");

  const bakedTexture = useTexture("./house.png");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.house.geometry}
        material={nodes.house.material}
        position={[-17.93, 2.24, -5.12]}
        rotation={[0, 0.11, 0]}
      >
        <meshStandardMaterial map={bakedTexture} map-flipY={false} />
      </mesh>
    </group>
  );
}