import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <span className="legal">
        <a target="_blank" href="./legal">
          Legal
        </a>
      </span>
      <span className="privacy">
        <a target="_blank" href="./privacy">
          Privacy
        </a>
      </span>
      <span className="privacy">
        <a target="_blank" href="./credits">
          Credits
        </a>
      </span>
    </div>
  );
}
