import React from "react";
import { useGLTF, useTexture } from "@react-three/drei";

export default function SkyLeft(props) {
  const { nodes } = useGLTF("/sky_left.glb");

  const bakedTexture = useTexture("./sky.jpg");

  return (
    <group {...props} dispose={null}>
      <mesh
        
        receiveShadow
        geometry={nodes.Sky_main002.geometry}
        material={nodes.Sky_main002.material}
        position={[-41.34, 8.2, -9.36]}
        rotation={[Math.PI, 0, Math.PI]}
      >
        <meshStandardMaterial map={bakedTexture} map-flipY={false} />
      </mesh>
    </group>
  );
}
