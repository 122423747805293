import React from "react";
import { Html } from "@react-three/drei";

import { useAnimationsStore } from "../../store/animation_store";
import "../../styles/components/navigationPanel.css";

function NavItem({ title, onClick }) {
  return (
    <div className="navItem hover_cursor" onClick={onClick}>
      <h1 style={{ color: "white !important" }}>{title}</h1>
    </div>
  );
}

export default function NavigationPanelHtml() {
  const {
    startContactFormAnimation,
    startAboutMeAnimation,
    startMyWorkAnimation,
  } = useAnimationsStore();

  return (
    <mesh>
      <Html
        occlude
        transform
        style={{ padding: "0" }}
        position={[1.42, -4.87, 28.24]}
        rotation={[-Math.PI / 5, 0, 0]}
      >
        <div className="navPanel__container hover_cursor">
          <div className="navPanel__wrapper hover_cursor">
            <NavItem title="About me" onClick={() => startAboutMeAnimation()} />
            <NavItem title="Work" onClick={() => startMyWorkAnimation()} />
            <NavItem
              title="Contact"
              onClick={() => startContactFormAnimation()}
            />
          </div>
        </div>
      </Html>
    </mesh>
  );
}
