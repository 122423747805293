import React from "react";

export default function FlipPhone({ isMobile, orientation }) {
  if (isMobile && orientation === "portrait-primary") {
    return (
      <div style={{ zIndex: 9999999999999 }}>
        For a better Experience, please use Landscape-mode
      </div>
    );
  }
  return null;
}
