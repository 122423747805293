import { PerformanceMonitor } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useState } from "react";

export default function SceneCanvas({ children }) {
  const [dpr, setDpr] = useState(1.5);

  return (
    <Canvas
      shadows
      gl={{ antialias: false }}
      mode="concurrent"
      dpr={dpr}
      camera={{
        fov: 25,
        near: 1,
        far: 250,
        position: [0, 55, 150],
        scale: [1, 1, 1],
        zoom: 1,
      }}
    >
      <PerformanceMonitor
        onIncline={() => setDpr(2)}
        onDecline={() => setDpr(1)}
      ></PerformanceMonitor>
      {children}
    </Canvas>
  );
}
