import { useGLTF } from "@react-three/drei";
import React from "react";
import { Fire } from "../../lib/shaders/Fire";

export default function Campfire(props) {
  const { nodes, materials } = useGLTF("/campfire.glb");

  return (
    <group {...props} dispose={null}>
      <group
        position={[-28.18, -1.48, 18.21]}
        rotation={[-0.06, 0, 0]}
        scale={2.04}
      >
        <Fire position={[0, 0.4, 0]} scale={[0.6, 1.3, 0.6]} color="red" />
        <mesh
          castShadow
          geometry={nodes.log001.geometry}
          material={materials["wood.004"]}
          position={[-0.29, 0, 0.2]}
          rotation={[1.44, 0.93, -1.74]}
        />
        <mesh
          castShadow
          geometry={nodes.burned_ground003.geometry}
          material={materials["ground_burned.001"]}
          position={[0, 0, 0.01]}
          rotation={[0, 1.5, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.burned_ground004.geometry}
          material={materials["ground_burned.001"]}
          position={[0.02, 0, -0.26]}
          rotation={[0, 1.5, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.burned_ground005.geometry}
          material={materials["ground_burned.001"]}
          position={[-0.02, 0, 0.26]}
          rotation={[0, 1.5, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.log005.geometry}
          material={materials["wood.004"]}
          position={[-0.2, 0, -0.33]}
          rotation={[-2.48, -0.22, -2.74]}
        />
        <mesh
          castShadow
          geometry={nodes.log003.geometry}
          material={materials["wood.004"]}
          position={[-0.31, 0, -0.13]}
          rotation={[1.47, 0.98, -1.23]}
        />
        <mesh
          castShadow
          geometry={nodes.log002.geometry}
          material={materials["wood.004"]}
          position={[-0.36, 0, 0.03]}
          rotation={[1.51, 0.56, -1.54]}
        />
        <mesh
          castShadow
          geometry={nodes.log004.geometry}
          material={materials["log_burned.001"]}
          position={[-0.23, 0.01, -0.23]}
          rotation={[1.76, 0.68, -1.03]}
        />
        <mesh
          castShadow
          geometry={nodes.log024.geometry}
          material={materials["log_burned.001"]}
          position={[0.21, 0, -0.26]}
          rotation={[0.84, 0.25, 0.59]}
        />
        <mesh
          castShadow
          geometry={nodes.log025.geometry}
          material={materials["wood.004"]}
          position={[0.3, 0, -0.24]}
          rotation={[1.24, -0.93, 0.94]}
        />
        <mesh
          castShadow
          geometry={nodes.log006.geometry}
          material={materials["log_burned.001"]}
          position={[-0.05, 0, -0.36]}
          rotation={[1.15, 0.01, -0.01]}
        />
        <mesh
          castShadow
          geometry={nodes.log023.geometry}
          material={materials["wood.004"]}
          position={[0.1, 0, -0.36]}
          rotation={[0.9, 0.01, 0.21]}
        />
        <mesh
          castShadow
          geometry={nodes.log028.geometry}
          material={materials["log_burned.001"]}
          position={[0.29, 0, 0.18]}
          rotation={[1.19, -0.52, 1.72]}
        />
        <mesh
          castShadow
          geometry={nodes.log027.geometry}
          material={materials["wood.004"]}
          position={[0.38, 0, 0.06]}
          rotation={[1.4, -0.65, 1.5]}
        />
        <mesh
          castShadow
          geometry={nodes.log026.geometry}
          material={materials["log_burned.001"]}
          position={[0.35, 0, -0.09]}
          rotation={[1.45, -0.48, 1.39]}
        />
        <mesh
          castShadow
          geometry={nodes.log029.geometry}
          material={materials["wood.004"]}
          position={[0.29, 0, 0.27]}
          rotation={[2.49, -0.28, 2.72]}
        />
        <mesh
          castShadow
          geometry={nodes.log031.geometry}
          material={materials["log_burned.001"]}
          position={[0.01, 0, 0.39]}
          rotation={[1.91, -0.21, -3.09]}
        />
        <mesh
          castShadow
          geometry={nodes.log032.geometry}
          material={materials["log_burned.001"]}
          position={[-0.15, 0.03, 0.32]}
          rotation={[2.02, -0.28, -2.77]}
        />
        <mesh
          castShadow
          geometry={nodes.stone012.geometry}
          material={materials["stone.001"]}
          position={[-0.28, 0, -0.38]}
          rotation={[0, -0.09, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.stone015.geometry}
          material={materials["stone.001"]}
          position={[-0.47, 0, -0.1]}
          rotation={[0, 0.45, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.stone014.geometry}
          material={materials["stone.001"]}
          position={[0.16, 0, -0.44]}
          rotation={[0, -0.96, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.stone016.geometry}
          material={materials["stone.001"]}
          position={[-0.05, 0, -0.48]}
          rotation={[0, 0.77, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.stone019.geometry}
          material={materials["stone.001"]}
          position={[0.48, 0, 0.13]}
          rotation={[Math.PI, -0.67, Math.PI]}
        />
        <mesh
          castShadow
          geometry={nodes.stone017.geometry}
          material={materials["stone.001"]}
          position={[-0.49, 0, 0.08]}
          rotation={[0, 1.45, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.stone020.geometry}
          material={materials["stone.001"]}
          position={[0.47, 0, -0.05]}
          rotation={[0, -0.79, 0]}
        />
        <mesh
          castShadow
          geometry={nodes.stone023.geometry}
          material={materials["stone.001"]}
          position={[0.07, 0, 0.5]}
          rotation={[-Math.PI, 0.66, -Math.PI]}
        />
        <mesh
          castShadow
          geometry={nodes.stone022.geometry}
          material={materials["stone.001"]}
          position={[-0.13, 0, 0.46]}
          rotation={[-Math.PI, 1.07, -Math.PI]}
        />
        <mesh
          castShadow
          geometry={nodes.stone021.geometry}
          material={materials["stone.001"]}
          position={[-0.37, 0, 0.29]}
          rotation={[-Math.PI, 1.5, -Math.PI]}
        />
        <mesh
          castShadow
          geometry={nodes.log030.geometry}
          material={materials["wood.004"]}
          position={[0.15, 0, 0.35]}
          rotation={[2.46, -0.21, 2.92]}
        />
        <mesh
          castShadow
          geometry={nodes.stone013.geometry}
          material={materials["stone.001"]}
          position={[0.4, 0, -0.29]}
          rotation={[Math.PI, -1.31, Math.PI]}
        />
        <mesh
          castShadow
          geometry={nodes.stone018.geometry}
          material={materials["stone.001"]}
          position={[0.29, 0, 0.42]}
          rotation={[Math.PI, -0.14, Math.PI]}
        />
      </group>
    </group>
  );
}
