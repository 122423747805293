import React, { forwardRef, useEffect, useRef } from "react";

import { extend, useFrame, useThree } from "@react-three/fiber";
import CameraControlsDefault from "camera-controls";
import * as THREE from "three";

CameraControlsDefault.install({ THREE });
extend({ CameraControlsDefault });

export const CameraControls = forwardRef((_, ref) => {
  const cameraControls = useRef(null);
  const camera = useThree((state) => state.camera);
  const renderer = useThree((state) => state.gl);

  useFrame((_, delta) => cameraControls.current?.update(delta));
  // remove event-listeners
  useEffect(() => () => cameraControls.current?.dispose(), []);
  // settings
  useEffect(() => {
    if (!Boolean(cameraControls.current)) return;
    cameraControls.current.minZoom = 5;
    cameraControls.current.maxZoom = 20;
    cameraControls.current.minDistance = 15;
    cameraControls.current.maxDistance = 200;
    cameraControls.current.minPolarAngle = -Math.PI / 2;
    cameraControls.current.maxPolarAngle = Math.PI / 2;
    cameraControls.current.minAzimuthAngle = -Math.PI / 12;
    cameraControls.current.maxAzimuthAngle = Math.PI / 2;
    cameraControls.current.azimuthRotateSpeed = -0.3;
    cameraControls.current.polarRotateSpeed = -0.3;
    cameraControls.current.truckSpeed = 10;
  }, []);

  return (
    <cameraControlsDefault
      ref={mergeRefs(cameraControls, ref)}
      args={[camera, renderer.domElement]}
    />
  );
});

function mergeRefs(...refs) {
  return (instance) => {
    for (const ref of refs) {
      if (typeof ref === "function") {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    }
  };
}
