import { createRoot } from "react-dom/client";
import { isMobile } from "react-device-detect";

import "./styles/base.css";
import "./styles/water.css";
import "./styles/components/overlaycontainer.css";
import "./styles/components/footer.css";
import "./styles/components/intro.css";
import "primeicons/primeicons.css";

import Scene from "./Scene";
import PreloadService from "./lib/helpers/preloadService";

const preloadService = new PreloadService(isMobile);

preloadService.preLoadModels();
preloadService.preLoadTextures();



createRoot(document.getElementById("root")).render(<Scene />);
