import { useAnimations, useGLTF } from "@react-three/drei";
import React, { Suspense, useEffect, useRef } from "react";

import { useAnimationsStore } from "../store/animation_store";
import { ContactForm } from "./_Html/ContactForm";
import "../styles/components/contactForm.css";

export default function Fisherman() {
  return (
    <Suspense fallback={<FishermanHD url={"/fisherman_sd_no_animation.glb"} />}>
      <FishermanHD url={"/fisherman.glb"} />;
    </Suspense>
  );
}

export function FishermanHD({ url }) {
  const group = useRef();
  const fish = useRef();
  const { nodes, materials, animations } = useGLTF(url);
  const { actions } = useAnimations(animations, group);

  const { contactFormAnimation } = useAnimationsStore();

  useEffect(() => {
    actions["BoatAction"].play();
    
    if (contactFormAnimation === true) {
      fish.current.visible = true;
      actions["FishermanAction"].setDuration(5);
      actions["FishAction"].setDuration(5);

      actions["FishermanAction"].clampWhenFinished = true;
      actions["FishermanAction"].setLoop("LoopOnce", 1);
      actions["FishAction"].clampWhenFinished = true;
      actions["FishAction"].setLoop("LoopOnce", 1);
      actions["FishermanAction"].play();
      actions["FishAction"].play();
    }

    if (contactFormAnimation === false) {
      fish.current.visible = false;
      actions["FishermanAction"]?.stop();
      actions["FishAction"]?.stop();
    }
  }, [contactFormAnimation, actions]);

  return (
    <group ref={group} dispose={null}>
      <group name="Scene">
        <group
          name="Boat"
          position={[29.78, -2.5, 16.43]}
          rotation={[0, -0.53, 0]}
          scale={1.68}
        >
          <mesh
            name="Cube003"
            geometry={nodes.Cube003.geometry}
            material={materials.boat}
          />
          <mesh
            name="Cube003_1"
            geometry={nodes.Cube003_1.geometry}
            material={materials["wood.001"]}
          />
          <mesh
            name="Cube003_2"
            geometry={nodes.Cube003_2.geometry}
            material={materials.metal}
          />
          <mesh
            name="Cube003_3"
            geometry={nodes.Cube003_3.geometry}
            material={materials.paddle}
          />
          <mesh
            name="Cube003_4"
            geometry={nodes.Cube003_4.geometry}
            material={materials["pot.001"]}
          />
          <mesh
            name="Cube003_5"
            geometry={nodes.Cube003_5.geometry}
            material={materials["black.002"]}
          />
          <mesh
            name="Cube003_6"
            geometry={nodes.Cube003_6.geometry}
            material={materials["rocks.001"]}
          />
          <mesh
            name="Cube003_7"
            geometry={nodes.Cube003_7.geometry}
            material={materials["rope.002"]}
          />
          <mesh
            name="Cube003_8"
            geometry={nodes.Cube003_8.geometry}
            material={materials["laterne_metal.001"]}
          />

          <mesh name="Cube003_9" geometry={nodes.Cube003_9.geometry}>
            <meshStandardMaterial
              color={0xfdee4b}
              emissive={0xfdee4b}
              emissiveIntensity={0.5}
            />
          </mesh>
          <group
            name="Fisherman"
            position={[0.09, 0.07, 0.05]}
            rotation={[0, 1.57, 0]}
            frustumCulled={false}
          >
            <primitive frustumCulled={false} object={nodes.rig_male_root} />
            <primitive
              frustumCulled={false}
              object={nodes.rig_male_foot_IK_L}
            />
            <primitive
              frustumCulled={false}
              object={nodes.rig_male_foot_IK_R}
            />
            <primitive
              frustumCulled={false}
              object={nodes.rig_male_hand_IK_L}
            />
            <primitive
              frustumCulled={false}
              object={nodes.rig_male_hand_IK_R}
            />
            <primitive frustumCulled={false} object={nodes.rig_fisherman_rod} />
            <group name="mesh_male001">
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002"
                geometry={nodes.mesh_male002.geometry}
                material={materials["fisherman_jacket.001"]}
                skeleton={nodes.mesh_male002.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_1"
                geometry={nodes.mesh_male002_1.geometry}
                material={materials["male_hair.001"]}
                skeleton={nodes.mesh_male002_1.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_2"
                geometry={nodes.mesh_male002_2.geometry}
                skeleton={nodes.mesh_male002_2.skeleton}
              >
                <meshStandardMaterial color={0xe0ac69} />
              </skinnedMesh>

              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_3"
                geometry={nodes.mesh_male002_3.geometry}
                skeleton={nodes.mesh_male002_3.skeleton}
              >
                <meshStandardMaterial color={0xe0ac69} />
              </skinnedMesh>
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_4"
                geometry={nodes.mesh_male002_4.geometry}
                material={materials["fisherman_pants.001"]}
                skeleton={nodes.mesh_male002_4.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_5"
                geometry={nodes.mesh_male002_5.geometry}
                material={materials["male_metal.001"]}
                skeleton={nodes.mesh_male002_5.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_6"
                geometry={nodes.mesh_male002_6.geometry}
                material={materials["white.001"]}
                skeleton={nodes.mesh_male002_6.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_7"
                geometry={nodes.mesh_male002_7.geometry}
                material={materials["black.001"]}
                skeleton={nodes.mesh_male002_7.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_8"
                geometry={nodes.mesh_male002_8.geometry}
                material={materials["male_shoes.002"]}
                skeleton={nodes.mesh_male002_8.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="mesh_male002_9"
                geometry={nodes.mesh_male002_9.geometry}
                material={materials["male_hat.001"]}
                skeleton={nodes.mesh_male002_9.skeleton}
              />
            </group>
            <group name="fishing_rod001">
              <skinnedMesh
                frustumCulled={false}
                name="Cube004"
                geometry={nodes.Cube004.geometry}
                material={materials["rod_stick.001"]}
                skeleton={nodes.Cube004.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="Cube004_1"
                geometry={nodes.Cube004_1.geometry}
                material={materials["rod_grip.001"]}
                skeleton={nodes.Cube004_1.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="Cube004_2"
                geometry={nodes.Cube004_2.geometry}
                material={materials["rod_metal.001"]}
                skeleton={nodes.Cube004_2.skeleton}
              />
              <skinnedMesh
                frustumCulled={false}
                name="Cube004_3"
                geometry={nodes.Cube004_3.geometry}
                material={materials["black.001"]}
                skeleton={nodes.Cube004_3.skeleton}
              />
            </group>
          </group>
        </group>
        <group
          name="Fish"
          position={[36.02, -8.55, 19.91]}
          rotation={[2.84, -1.11, 2.87]}
          ref={fish}
          visible={false}
        >
          <mesh
            name="Cube131"
            geometry={nodes.Cube131.geometry}
            material={materials.fish_main}
          />
          <mesh
            name="Cube131_1"
            geometry={nodes.Cube131_1.geometry}
            material={materials.fish_mouth}
          />
          <mesh
            name="Cube131_2"
            geometry={nodes.Cube131_2.geometry}
            material={materials.fish_secondary}
          >
            <ContactForm />
          </mesh>
          <mesh
            name="Cube131_3"
            geometry={nodes.Cube131_3.geometry}
            material={materials.fish_white}
          />
          <mesh
            name="Cube131_4"
            geometry={nodes.Cube131_4.geometry}
            material={materials.fish_black}
          />
        </group>
      </group>
    </group>
  );
}
