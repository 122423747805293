import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes } = useGLTF("/water.glb");
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.WaterSurface.geometry}>
        <meshStandardMaterial transparent opacity={0.95} color={0x6ba09e}/>
      </mesh>
    </group>
  );
}
