import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";

export default function Logs(props) {
  const { scene } = useGLTF("/logs.glb");
  const copiedScene = useMemo(() => scene.clone(), [scene]);
  const { nodes, materials } = useGraph(copiedScene);

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        geometry={nodes.prop_log_01001.geometry}
        material={materials["wood.006"]}
        position={[-31.16, -1.48, 19.61]}
        rotation={[0, 0.15, 0]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_01002.geometry}
        material={materials["wood.006"]}
        position={[-30.87, -1.5, 17.74]}
        rotation={[Math.PI, -1.57, Math.PI]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_01.geometry}
        material={materials.Stamm}
        position={[-2.21, -0.43, 19.12]}
        rotation={[1.8, 0.17, -0.94]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_03.geometry}
        material={materials.Stamm}
        position={[-30.91, -0.63, 17.44]}
        rotation={[-Math.PI / 2, 0, 3.03]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02002.geometry}
        material={materials.Stamm}
        position={[-12.48, 2.47, 5.42]}
        rotation={[1.54, -0.16, -0.43]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02003.geometry}
        material={materials.wood}
        position={[-31.85, -1.35, 21.22]}
        rotation={[1.48, 0.05, -1.97]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02001.geometry}
        material={materials.Stamm}
        position={[-11.71, 2.4, 5.79]}
        rotation={[1.53, 0.07, -1.37]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02.geometry}
        material={materials.Stamm}
        position={[-11.84, 2.37, 4.94]}
        rotation={[1.32, 0.12, -1.96]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02005.geometry}
        material={materials.wood}
        position={[-32.41, -1.22, 21.78]}
        rotation={[1.43, -0.04, -0.5]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02004.geometry}
        material={materials.wood}
        position={[-31.74, -1.29, 22.03]}
        rotation={[1.48, -0.03, -1.46]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02008.geometry}
        material={materials.wood}
        position={[-31.5, -1.29, 21.32]}
        rotation={[3.07, -0.07, -1.57]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02007.geometry}
        material={materials.wood}
        position={[-31.74, -1.29, 21.64]}
        rotation={[3.07, -0.07, -1.57]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_log_02009.geometry}
        material={materials.wood}
        position={[-31.46, -1.12, 21.03]}
        rotation={[1.5, -0.06, -1.1]}
      />
    </group>
  );
}
