import { useContext } from "react";
import { DeviceDetectContext } from "../Scene";
import { useSpring, animated, config } from "@react-spring/three";
import { useAnimationsStore } from "../store/animation_store";
import {
  AMBIENT_LIGHT_CLR,
  POINT_LIGHT_CLR,
  WINDOW_LIGHT_CLR,
  AMBIENT_LIGHT_INTENSITY,
  POINT_LIGHT_INTENSITY,
  LANTERN_LIGHT_CLR,
} from "../store/light_constants";

export default function SceneLights() {
  const isMobile = useContext(DeviceDetectContext);

  return (
    <>
      {!isMobile && <LanternLights />}
      <EnvLights isMobile={isMobile} />
      <HouseLights />
    </>
  );
}

////////////////////////

function EnvLights({ isMobile }) {
  return (
    <>
      <pointLight
        size={50}
        intensity={POINT_LIGHT_INTENSITY}
        position={[45, 15, 15]}
        castShadow={isMobile ? false : true}
        color={POINT_LIGHT_CLR}
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
      />

      <ambientLight
        intensity={AMBIENT_LIGHT_INTENSITY}
        color={AMBIENT_LIGHT_CLR}
      />
    </>
  );
}

function HouseLights() {
  const { myWorkAnimation } = useAnimationsStore();

  const { intensity } = useSpring({
    from: { intensity: 0 },
    intensity: myWorkAnimation ? 1 : 0,
    delay: 2000,
    config: config.wobbly,
  });

  return (
    <>
      {/* door */}
      <animated.rectAreaLight
        position={[-15.95, 7.5, -3.5]}
        rotation={[0, 0.08, 0]}
        width={1.34}
        height={3}
        color={WINDOW_LIGHT_CLR}
        intensity={intensity.to((i) => i.toFixed(2))}
      />
      {/* left small window */}
      <animated.rectAreaLight
        position={[-22.42, 8.16, -2.68]}
        rotation={[0, 0.08, 0]}
        width={1.28}
        height={1.28}
        color={WINDOW_LIGHT_CLR}
        intensity={intensity.to((i) => i.toFixed(2))}
      ></animated.rectAreaLight>

      {/* right small window  */}
      <animated.rectAreaLight
        position={[-20.78, 8.16, -2.83]}
        rotation={[0, 0.08, 0]}
        width={1.28}
        height={1.28}
        color={WINDOW_LIGHT_CLR}
        intensity={intensity.to((i) => i.toFixed(2))}
      ></animated.rectAreaLight>
    </>
  );
}

function LanternLights() {
  return (
    <>
      {/* Boat
      <pointLight
        position={[31.55, -0.32, 17.52]}
        color={LANTERN_LIGHT_CLR}
        intensity={0.65}
        decay={2}
        distance={15}
        castShadow
      ></pointLight>
       Lantern at House 
      <pointLight
        position={[-6.37, 3.41, 2.33]}
        color={LANTERN_LIGHT_CLR}
        intensity={0.85}
        decay={2}
        distance={20}
        castShadow
      ></pointLight> */}

      {/* Lantern at Lake */}
      <pointLight
        position={[18.25, 0.95, 11.5]}
        color={LANTERN_LIGHT_CLR}
        intensity={0.85}
        decay={2}
        distance={20}
        castShadow
      ></pointLight>
    </>
  );
}
