import { Instance, Instances, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useRef } from "react";

const pos_and_rot = [
  { position: [-30.05, 7.44, -26.72], rotation: [0.04, 1.07, -0.16] },
  { position: [-37.03, 6.87, -27.84], rotation: [-0.26, 1.4, 0.32] },
  { position: [-35.46, 7.02, -22.1], rotation: [0.01, 1.21, -0.03] },
  { position: [-35.58, 2.69, -5.21], rotation: [-0.5, -1.47, -0.51] },
  { position: [-33.51, 2.69, -10.11], rotation: [0, 0, 0] },
  { position: [-37.32, 1.47, -9.59], rotation: [0, 1.3, 0] },
  { position: [-21.82, 7.58, -19.97], rotation: [0, 0, 0] },
  { position: [-25.59, 7.84, -22.73], rotation: [0, 0, 0] },
  { position: [-22.89, 7.69, -25.91], rotation: [Math.PI, -1.32, Math.PI] },
  { position: [-18.84, 7.64, -27.48], rotation: [0, -1.14, 0] },
  { position: [-17.84, 8.21, -21.55], rotation: [0, 0, 0] },
  { position: [-20.09, 7.8, -23.43], rotation: [0, 1.24, 0] },
  { position: [-14.58, 7.72, -25.62], rotation: [0, -0.26, 0] },
  { position: [-14.68, 7.29, -20.68], rotation: [0, 0, 0] },
  { position: [-10.92, 7.75, -20.63], rotation: [3.12, 1.02, -3.1] },
  { position: [-11.62, 7.27, -24.23], rotation: [0, 0, 0] },
  { position: [34.18, 0.27, -24.85], rotation: [0, -0.09, 0] },
  { position: [29.93, 0.28, -24.23], rotation: [0, -0.08, 0] },
  { position: [31.83, 0.42, -22.34], rotation: [0, -0.19, 0] },
  { position: [35.97, 0.46, -21.85], rotation: [0, -0.44, 0] },
  { position: [29.75, 0.07, -19.51], rotation: [0, -0.31, 0] },
  { position: [36.72, 0.47, -18.82], rotation: [0, 0.57, 0] },
  { position: [39.79, 0.64, -20.53], rotation: [0, -0.13, 0] },
  { position: [40.35, 0.45, -23.15], rotation: [0, 0.03, 0] },
  { position: [41.56, 0.42, -26.24], rotation: [0, 0.12, 0] },
  { position: [36.08, 0.38, -26.79], rotation: [0, -0.52, 0] },
  { position: [43.14, 0.34, -22.56], rotation: [0, 0.38, 0] },
  { position: [37.73, 0.29, -16.8], rotation: [0, 0.27, 0] },
  { position: [43.8, -0.18, -12.49], rotation: [0, -0.75, 0] },
  { position: [39.75, 0.17, -14.63], rotation: [0, -0.68, 0] },
  { position: [27.63, -0.09, -24.36], rotation: [0, 0.38, 0] },
  { position: [42.62, 0, -15.31], rotation: [0, -0.44, 0] },
  { position: [40.63, -0.03, -11.23], rotation: [0, -0.52, 0] },
  { position: [28.33, 0.02, -28.36], rotation: [0, 0.21, 0] },
  { position: [31.39, 0.33, -17.94], rotation: [0, 0.68, 0] },
  { position: [38.05, 0.13, -14.08], rotation: [0, -0.11, 0] },
  { position: [-38.87, -0.5, 12], rotation: [-0.5, -1.47, -0.51] },
  { position: [-30.06, 2.26, -6.97], rotation: [-0.2, 1.26, 0.25] },
  { position: [35.1, 0.14, -13.53], rotation: [0, -0.76, 0] },
  { position: [-37.24, -0.51, 14.58], rotation: [-0.5, -1.47, -0.51] },
  { position: [-31.11, 1.8, -2.92], rotation: [0.01, 1.21, -0.03] },
  { position: [-30.59, 7.6, -21.79], rotation: [3.01, 1.3, -3.06] },
];

function Tree({ ...props }) {
  const ref = useRef();

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.x = Math.cos(t * 0.5) / 15;
    ref.current.rotation.z = Math.sin(t* 0.5) / 15;
  });

  return (
    <group {...props}>
      <Instance ref={ref} />
    </group>
  );
}

export default function Trees() {
  const { nodes, materials } = useGLTF("/tree.glb");

  return (
    <>
      {/* TODO comobine Tree.gltf to one mesh and material */}

      <Instances
        geometry={nodes.Cube118_1.geometry}
        material={materials["tree_leaves.005"]}
        castShadow
      >
        <group position={[0, 0, 0]}>
          {pos_and_rot.map((props, i) => (
            <Tree key={i} {...props} />
          ))}
        </group>
      </Instances>

      <Instances
        material={materials["pine_tree_trunk.005"]}
        geometry={nodes.Cube118.geometry}
        castShadow
      >
        <group position={[0, 0, 0]}>
          {pos_and_rot.map((props, i) => (
            <Tree key={i} {...props} />
          ))}
        </group>
      </Instances>
    </>
  );
}

