import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Laternen(props) {
  const { nodes, materials } = useGLTF("/laternen.glb");

  return (
    <group {...props} dispose={null}>
      <group position={[18.75, -0.44, 11.04]} rotation={[0.1, -0.87, 0.06]}>
        <mesh
          castShadow
          geometry={nodes.Cube.geometry}
          material={materials.rocks}
        />
        <mesh
          castShadow
          geometry={nodes.Cube_1.geometry}
          material={materials["wood.003"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube_2.geometry}
          material={materials["rope.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube_3.geometry}
          material={materials.laterne_metal}
        />
        <mesh transparent geometry={nodes.Cube109_4.geometry}>
          <meshStandardMaterial
            color={0xfdee4b}
            emissive={0xfdee4b}
            emissiveIntensity={0.5}
          />
        </mesh>
      </group>
      <group position={[-6.2, 2.07, 1.78]} rotation={[0, -0.3, 0]}>
        <mesh
          castShadow
          geometry={nodes.Cube109.geometry}
          material={materials.rocks}
        />
        <mesh
          castShadow
          geometry={nodes.Cube109_1.geometry}
          material={materials["wood.003"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube109_2.geometry}
          material={materials["rope.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube109_3.geometry}
          material={materials.laterne_metal}
        />
        <mesh transparent geometry={nodes.Cube109_4.geometry}>
          <meshStandardMaterial
            color={0xfdee4b}
            emissive={0xfdee4b}
            emissiveIntensity={0.5}
          />
        </mesh>
      </group>
    </group>
  );
}

