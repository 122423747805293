import NavigationPanelContainer from "./NavigationPanelContainer";
import NavigationPanelHtml from "../_Html/NavigationPanelHtml";

export default function NavigationPanel() {
  return (
    <>
      <NavigationPanelContainer />
      <NavigationPanelHtml />
    </>
  );
}
