import SkyLeft from "./SkyLeft";
import SkyMain from "./SkyMain";

export default function TerrainSky() {
  return (
    <>
      <SkyLeft />
      <SkyMain />
    </>
  );
}
