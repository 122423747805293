import React, { createContext } from "react";
import { Stats, useDetectGPU } from "@react-three/drei";
import { isMobile } from "react-device-detect";

import GoBackButtonOverlay from "./components/_Html/GoBackButtonOverlay";
import AboutMeSection from "./components/_Html/AboutSection";
import MyWorkSection from "./components/_Html/MyWorkSection";
import Intro from "./components/Intro";
import Footer from "./components/_Html/Footer";
import useScreenOrientation from "./lib/screenOrientation";
import { useAnimationsStore } from "./store/animation_store";

// const SceneLights = React.lazy(() => import("./lib/SceneLights"));
// const SceneAssets = React.lazy(() => import("./components"));
// const SceneCanvas = React.lazy(() => import("./lib/SceneCanvas"));
// const CameraControlsWithAnimations = React.lazy(() =>
//   import("./lib/cameraAnimations")
// );
// const FlipPhone = React.lazy(() =>
//   import("./components/FlipPhoneNotification")
// );

import FlipPhone from "./components/FlipPhoneNotification";
import SceneLights from "./lib/SceneLights";
import SceneAssets from "./components";
import SceneCanvas from "./lib/SceneCanvas";
import CameraControlsWithAnimations from "./lib/cameraAnimations";

function Experience() {
  return (
    <>
      <SceneCanvas>
        <CameraControlsWithAnimations />
        <SceneAssets />
        <SceneLights />
      </SceneCanvas>
    </>
  );
}

export const DeviceDetectContext = createContext();

export default function Scene() {
  const GPUTier = useDetectGPU();

  const { aboutMeAnimation, myWorkAnimation } = useAnimationsStore();
  const orientation = useScreenOrientation();

  return (
    <>
      <GoBackButtonOverlay />
      <AboutMeSection aboutMeAnimation={aboutMeAnimation} />
      <MyWorkSection myWorkAnimation={myWorkAnimation} />
      <Stats showPanel={0} className="stats" />

      <Intro>
        <FlipPhone isMobile={isMobile} orientation={orientation}></FlipPhone>
        <DeviceDetectContext.Provider
          value={
            GPUTier.tier === "1" ||
            GPUTier.tier === "2" ||
            GPUTier.tier === "0" ||
            GPUTier.isMobile
          }
        >
          <Experience />
        </DeviceDetectContext.Provider>
      </Intro>
      <Footer />
    </>
  );
}
