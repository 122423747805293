import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Bench(props) {
  const { nodes, materials } = useGLTF("/bench.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.prop_bench.geometry}
        material={materials.Stamm}
        position={[32.23, -0.56, 6.29]}
        rotation={[0, -0.66, 0.02]}
        scale={1.58}
      />
    </group>
  );
}
