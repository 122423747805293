import React from "react";
import { useGLTF } from "@react-three/drei";

export default function NavigationPanelContainer(props) {
  const { nodes } = useGLTF("/navigation_panel.glb");

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.NavigationPanel.geometry}
        position={[1.5, -1.67, 24.74]}
        rotation={[-Math.PI / 6, 0, 0]}
      >
        <meshToonMaterial color={0x545454}></meshToonMaterial>
      </mesh>
    </group>
  );
}
