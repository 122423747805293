import { useGLTF, useTexture } from "@react-three/drei";
import React, { useRef } from "react";

export default function Tent(props) {
  const group = useRef();
  const { nodes } = useGLTF("/tent.glb");

  const bakedTexture = useTexture("./tent.png");

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="tent"
          geometry={nodes.tent016.geometry}
          material={nodes.tent016.material}
          position={[0, -0.22, 0]}
        >
          <meshStandardMaterial map={bakedTexture} map-flipY={false} />
        </mesh>
      </group>
    </group>
  );
}
