import React, { useEffect, useState } from "react";

export default function AboutMe({ aboutMeAnimation }) {
  const [cssClassName, setCssClassName] = useState("overlaycontainer");

  useEffect(() => {
    if (aboutMeAnimation) {
      return setCssClassName("overlaycontainer fadeIn");
    } else {
      setCssClassName("overlaycontainer");
    }
  }, [aboutMeAnimation]);

  return (
    <div
      className={cssClassName}
      style={{ pointerEvents: aboutMeAnimation ? "all" : "none" }}
    >
      <p>
        Hey! I am Johannes and maybe you have guessed it, my name is
        <strong> Johannes</strong>
      </p>

      <p>
        I am a <strong> Software Developer</strong> from Germany living in
        <strong> Finland</strong> and founder of the company
        <strong> KarhuKoding</strong>
      </p>

      <p>I spent the last 6 years creating web applications.</p>

      <p>
        Currently I am focusing on <strong> 3D modelling and Three.js.</strong>
      </p>

      <p>
        <strong>My main goal</strong> is to use the today's technologies'
        variety to stretch the boundaries of the known UX for web applications
        in a useful way.
      </p>

      <p>
        <strong>My Vision for the Web</strong> is to have performant 3D
        visualisations, where they make sense, without breaking the known way of
        operating it for the User
      </p>
    </div>
  );
}
