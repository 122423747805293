import React, { useEffect, useState } from "react";

export default function MyWorkd({ myWorkAnimation }) {
  const [cssClassName, setCssClassName] = useState("overlaycontainer");

  useEffect(() => {
    if (myWorkAnimation) {
      return setCssClassName("overlaycontainer fadeIn");
    } else {
      setCssClassName("overlaycontainer");
    }
  }, [myWorkAnimation]);

  return (
    <div
      className={cssClassName}
      style={{ pointerEvents: myWorkAnimation ? "all" : "none" }}
    >
      <h3>
        Worked on the "frontend-side" for inhouse applications for multiple
        companies
      </h3>

      <ul>
        <li>Mastercard</li>
        <li>Deloitte</li>
        <li>Small and medium size companies (SMEs)</li>
        <li>
          Applications are mostly CRUD-applications which have a purpose of
          replacing / renewing existing software or simplifying business
          workflows.
        </li>
      </ul>

      <h3>3D Side-Projects</h3>

      <ul style={{ margin: 0, padding: 0, listStyleType: "none" }}>
        <li
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <iframe
            width="75%"
            height="315"
            src="https://www.youtube.com/embed/Z2wXbgTIpmU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br />
          <a
            style={{ pointer: "cursor" }}
            target="_blank"
            rel="noreferrer"
            href="https://shelf-builder.vercel.app/"
          >
            Try it out
          </a>
        </li>
      </ul>
    </div>
  );
}
