import { useProgress } from "@react-three/drei";
import { Suspense, useEffect, useState } from "react";

function Ready({ setReady }) {
  // setReady(true);
  // TODO why is useEffect not triggered
  setReady(true);
  // useEffect(
  //   () =>  {
  //     setReady(true);
  //   },
  //   [setReady]
  // );

  return null;
}

function Loader() {
  const { progress } = useProgress();
  return <div>loading {progress.toFixed()} %</div>;
}

export default function Intro({ children }) {
  const [ready, setReady] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {
      console.log("go to Application");
    }
  }, [clicked, ready]);

  return (
    <>
      <Suspense fallback={<Ready setReady={setReady} />}>{children}</Suspense>
      <div
        className={`fullscreen bg ${ready ? "ready" : "notready"} ${
          clicked && "clicked"
        }`}
      >
        <div className="intro_container">
          <div className="intro_text">
            <h1 className="intro_text--h1">
              KarhuKod<span className="intro_text--i">ı</span>ng
            </h1>
            <div
              className="pointer continue-link"
              onClick={() => setClicked(true)}
            >
              {!ready ? <span>...</span> : <span>click to continue</span>}
            </div>
          </div>

          <div className="intro_loading">
            <h5>{!ready ? <Loader /> : "loaded"}</h5>
          </div>
        </div>
      </div>
    </>
  );
}
