import { Html } from "@react-three/drei";
import React, { useState } from "react";

function CopyContentBox({ children, textToCopy }) {
  const defaultText = "Click to copy";

  const [helperText, setHelperText] = useState(defaultText);
  const [showHelperText, setShowHelperText] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(textToCopy);
    setHelperText("Copied");
  };

  return (
    <>
      <code>
        {children}
        <span
          style={{ paddingLeft: "2.5rem" }}
          onMouseEnter={() => setShowHelperText(true)}
          onMouseLeave={() => {
            setShowHelperText(false);
            setHelperText(defaultText);
          }}
          onClick={() => copy()}
        >
          <i className="pi pi-copy pointer"></i>
        </span>
      </code>

      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "12px", height: "1rem" }}>
          {showHelperText ? helperText : " "}
        </p>
      </div>
    </>
  );
}

function Title() {
  return <h2 className="closebutton_title">Say hello 👋</h2>;
}

function ContactOptions() {
  return (
    <div className="contactOptions">
      <CopyContentBox textToCopy="johannes@walenta.dev">
        <a href="mailto:email@example.com">johannes@walenta.dev</a>
      </CopyContentBox>

      <CopyContentBox textToCopy="https://github.com/timebasher">
        <i className="pi pi-github"></i>&nbsp;&nbsp;
        <a
          href="https://github.com/KarhuKoding"
          target="_blank"
          rel="noreferrer"
        >
          Github
        </a>
      </CopyContentBox>
    </div>
  );
}

export function ContactForm() {
  return (
    <Html
      as="div"
      occlude
      transform
      rotation={[0, Math.PI / 2, 0]}
      scale={0.3}
      className="contactForm_wrapper"
    >
      <div className="contactForm_container">
        <Title />
        <ContactOptions />
      </div>
    </Html>
  );
}
