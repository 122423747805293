import React from "react";

import backArrow from "../../assets/back-arrow.png";
import { useAnimationsStore } from "../../store/animation_store";
import "../../styles/components/goBackButton.css";

export default function GoBack() {
  const {
    viewBackToWorldOverlay,
    resetAnimations,
    resetViewBackToWorldOverlay,
  } = useAnimationsStore();

  if (viewBackToWorldOverlay) {
    return (
      <div className="goBack__container">
        <div
          className="goBack__wrapper"
          onClick={() => {
            resetAnimations();
            resetViewBackToWorldOverlay();
          }}
        >
          <div className="goBack__action">
            <img
              className="goBack__action--arrow"
              src={backArrow}
              alt="Back Button"
            />
          </div>

          <div className="goBack__info">
            <h1>Return to World</h1>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
