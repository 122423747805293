import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Stones(props) {
  const { nodes } = useGLTF("/stones.glb");

  const stoneMaterial = nodes.prop_rock_03001.material;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        geometry={nodes.prop_rock_02.geometry}
        material={stoneMaterial}
        position={[37.28, -0.18, -10.46]}
        rotation={[3.04, -0.96, 3.14]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_rock_02001.geometry}
        material={stoneMaterial}
        position={[30.03, -0.46, 5.75]}
        rotation={[-0.05, 0.1, 0.06]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_rock_01001.geometry}
        material={stoneMaterial}
        position={[26.41, -0.61, -9.21]}
        rotation={[0.1, 0.01, 0.14]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_rock_03001.geometry}
        material={stoneMaterial}
        position={[-27.57, -1.42, 14.43]}
        rotation={[-0.04, 0, 0.06]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_rock_04.geometry}
        material={stoneMaterial}
        position={[-2.41, 1.68, -1.03]}
        rotation={[0, -0.01, -0.11]}
        scale={1.27}
      />
      <mesh
        castShadow
        geometry={nodes.prop_rock_03002.geometry}
        material={stoneMaterial}
        position={[-34.87, 2.28, -2.38]}
        rotation={[0, 0, -0.03]}
      />
      <mesh
        castShadow
        geometry={nodes.prop_stone.geometry}
        material={stoneMaterial}
        position={[1.04, 6.75, -20.21]}
        rotation={[0.29, -0.27, 0.11]}
        scale={3.87}
      />
      <mesh
        castShadow
        geometry={nodes.prop_rock_02002.geometry}
        material={stoneMaterial}
        position={[12.15, -0.53, 13.88]}
        rotation={[2.9, -0.84, -Math.PI]}
        scale={0.81}
      />
      <mesh
        castShadow
        geometry={nodes.prop_rock_03003.geometry}
        material={stoneMaterial}
        position={[-39.08, -0.41, 17.33]}
        rotation={[-3.1, 0.21, -3.09]}
        scale={0.79}
      />
    </group>
  );
}


