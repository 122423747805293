import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Deck(props) {
  const { nodes, materials } = useGLTF("/deck.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow        
        geometry={nodes.steg.geometry}
        material={materials.Stamm}
        position={[17.9, -0.53, 14.13]}
      />
    </group>
  );
}
