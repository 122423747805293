import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Terrain(props) {
  const { nodes } = useGLTF("/grundflaeche.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        receiveShadow
        castShadow
        renderReverseSided={false}
        geometry={nodes.Plane273.geometry}
      >
        <meshStandardMaterial color={0x5e7028} />
      </mesh>
      <mesh
        receiveShadow
        castShadow
        renderReverseSided={false}
        geometry={nodes.Plane273_1.geometry}
      >
        <meshStandardMaterial color={0x7d3412} />
      </mesh>
      <mesh
        receiveShadow
        castShadow
        renderReverseSided={false}
        geometry={nodes.Plane273_2.geometry}
      >
        <meshStandardMaterial color={0xbda26b} />
      </mesh>
      <mesh
        receiveShadow
        castShadow
        renderReverseSided={false}
        geometry={nodes.Plane273_3.geometry}
      >
        <meshStandardMaterial color={0x8d7b50} />
      </mesh>
    </group>
  );
}
