import { useAnimations, useGLTF } from "@react-three/drei";
import React, { Suspense, useEffect, useRef } from "react";

import { useAnimationsStore } from "../store/animation_store";

export default function Bear() {
  return (
    <Suspense fallback={<BearHD url={"/bear_sd_no_animation.glb"} />}>
      <BearHD url={"/bear.glb"} />;
    </Suspense>
  );
}

export function BearHD({ url }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(url);
  const { actions, mixer } = useAnimations(animations, group);

  const { aboutMeAnimation } = useAnimationsStore();

  useEffect(() => {
    if (aboutMeAnimation === true) {
      actions["bear_hello"].clampWhenFinished = true;
      actions["bear_hello"].setLoop("LoopOnce", 1);
      actions["bear_hello"].play();
    }

    if (aboutMeAnimation === false) {
      actions["bear_hello"]?.stop();
    }
  }, [aboutMeAnimation, actions]);



  return (
    <group ref={group} dispose={null}>
      <group name="Scene">
        <group
          name="bear"
          position={[-30.07, -1.26, 14.65]}
          rotation={[0.08, 0.28, -0.04]}
          frustumCulled={false}
        >
          <skinnedMesh
            name="Cube134"
            frustumCulled={false}
            geometry={nodes.Cube134.geometry}
            material={materials["bear_brown_light.001"]}
            skeleton={nodes.Cube134.skeleton}
          />
          <skinnedMesh
            name="Cube134_1"
            frustumCulled={false}
            geometry={nodes.Cube134_1.geometry}
            material={materials["bear black .001"]}
            skeleton={nodes.Cube134_1.skeleton}
          />
          <skinnedMesh
            name="Cube134_2"
            frustumCulled={false}
            geometry={nodes.Cube134_2.geometry}
            material={materials["bear light.001"]}
            skeleton={nodes.Cube134_2.skeleton}
          />
          <skinnedMesh
            name="Cube134_3"
            frustumCulled={false}
            geometry={nodes.Cube134_3.geometry}
            material={materials["bear_white.001"]}
            skeleton={nodes.Cube134_3.skeleton}
          />
          <group name="rig_bear001" rotation={[-0.03, 0.03, 0.06]} scale={1.96}>
            <primitive
              frustumCulled={false}
              object={nodes.rig_bear_foot_back_IK_L}
            />
            <primitive frustumCulled={false} object={nodes.rig_bear_root} />
            <primitive
              frustumCulled={false}
              object={nodes.rig_bear_foot_back_IK_R}
            />
            <primitive
              frustumCulled={false}
              object={nodes.rig_bear_foot_front_IK_L}
            />
            <primitive
              frustumCulled={false}
              object={nodes.rig_bear_foot_front_IK_R}
            />
          </group>
        </group>
      </group>
    </group>
  );
}
