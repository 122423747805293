import { useAnimationsStore } from "../../store/animation_store";

import WaterSD from "./WaterSD";
import WaterViewBlocker from "./WaterViewBlocker";

export default function InteractiveWater() {
  const { startContactFormAnimation } = useAnimationsStore();

  return (
    <>
      <WaterSD onClick={() => startContactFormAnimation()} />
      <WaterViewBlocker />
    </>
  );
}
