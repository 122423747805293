import React from "react";
import { useGLTF } from "@react-three/drei";

export default function HunterStandSD(props) {
  const { nodes, materials } = useGLTF("/hunterstand_sd.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[29.02, -0.37, -10.81]}
        rotation={[-3.13, 0.45, 3.12]}
        scale={0.95}
      >
        <mesh
          castShadow
          geometry={nodes.Cube016.geometry}
          material={materials["Wood.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube016_1.geometry}
          material={materials["Wood_3.001"]}
        />
        <mesh
          castShadow
          geometry={nodes.Cube016_2.geometry}
          material={materials["Wood_2.001"]}
        />
      </group>
    </group>
  );
}
